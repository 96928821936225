'use client'

import { Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import SubmitButtonComponent from "@/components/buttons/SubmitButtonComponent";
import { SvgImages } from "@/lib/Constants";
import React, { useEffect } from "react";
import { useClientLogsMutation } from "@/lib/store";

// Error components must be Client Components
export default function Error({ error, reset }) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const appId = sessionStorage.getItem("appId");
  const stageName = sessionStorage.getItem("stageName");

  const [
    fetchClientLogs,
    { data, isLoading, isSuccess, isError },
  ] = useClientLogsMutation();

  console.log("error", error, "errorMessage", error.message);

  useEffect(() => {
    let data = {};
    data.errorMessage = error.message;
    data.stageName = stageName;
    fetchClientLogs({
      "app_id": appId,
      "data": data,
    });
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          width: isSmallScreen ? '80%' : '50%',
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Image
              src={SvgImages.redCrossIcon}
              alt="error"
              style={{ height: '90px', width: '90px', marginBottom: '20px' }}
            />
            <Typography variant="h5" style={{ color: 'gray', marginBottom: '20px' }}>
              Something went wrong, please try again later.
            </Typography>
            <Typography variant="body1" style={{ color: 'gray', marginBottom: '20px' }}>
              We apologize for the inconvenience. Please try refreshing the page or check back later. If this issue persists, feel free to contact our support team for assistance.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <SubmitButtonComponent
              buttonStyle={{
                width: '20%',
                padding: '10px',
                borderRadius: 5,
                backgroundColor: '#009640',
              }}
              onClick={() => reset()}
            >
              OK
            </SubmitButtonComponent>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
